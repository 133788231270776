import React from 'react'

function Footer() {
  return (
    <div className="footer"><div className="container">
      &copy; Kakapo Labs 2022
   </div> </div>
  )
}

export default Footer
