import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'


function App() {
  return (
    <div className="app">
      <Header />
      <div className="content">
        <div className="container">
          <h1>Client facing app</h1>
         Lorem ipsum dolor
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
