import React from 'react'

function Header() {
  return (
    <div className="header"><div className="container">
      <h1>MingleBee 🐝</h1>
   </div> </div>
  )
}

export default Header
